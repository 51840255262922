// NOTE: To avoid circular dependencies, do not import our UI widgets into this file, as several
// of them use the interfaces and/or utility functions defined here. If you need to create a new
// utility function that uses a UI widget, put it in that widget's file instead of this one.
import { Arr, Is, Str } from "core";
import ColorUtil = require("Everlaw/ColorUtil");
import { ColorTokens } from "design-system";
import Dom = require("Everlaw/Dom");
import TextBox = require("Everlaw/UI/TextBox");
import Util = require("Everlaw/Util");
import baseWindow = require("dojo/_base/window");
import dijit_Tooltip = require("dijit/Tooltip");
import dojo_cookie = require("dojo/cookie");
import dojo_io_iframe = require("dojo/io/iframe");
import dojo_keys = require("dojo/keys");
import dojo_on = require("dojo/on");
import eventUtil = require("dojo/_base/event");

export function hideTooltips() {
    const master = dijit_Tooltip._masterTT;
    if (master) {
        if (master._onDeck) {
            master.hide(master._onDeck[1]);
        }
        master.hide(master.aroundNode);
    }
}

/**
 * Toggle the disabled state of a button/icon.
 * These widgets use the disabled attribute for styling and to manage whether callbacks get fired.
 * Some more complicated widgets (e.g. descendants of Toggle) manage their own disabled state,
 * in which case you should use that mechanism instead of this method.
 * However, a few of these are handled by checking for the `setDisabled` function.
 * @param {type} obj a button or icon returned by UI.button or UI.icon
 * @param {type} state  true means disabled, false means enabled. Not passing, or passing undefined
 *  causes a toggle from current state.
 */
export function toggleDisabled(obj: any, state?: boolean) {
    if (Is.func(obj.setDisabled)) {
        obj.setDisabled(state);
    } else if (state) {
        Dom.setAttr(obj, "disabled", "true");
        if (Dom.node(obj)) {
            // Make sure to hide any tooltip around this object when it is disabled, otherwise it will
            // linger!
            dijit_Tooltip.hide(Dom.node(obj));
        }
    } else {
        Dom.removeAttr(obj, "disabled");
    }
}

export function userBadge(
    user?: { initials(): string | undefined },
    color?: string,
    extraClasses = "",
) {
    const letters = user ? user.initials() : "U";
    const actualColor =
        color || (user ? ColorUtil.colorAsHex(user) : ColorTokens.USER_BADGE_PRIMARY);
    return Dom.div(
        { class: "user-badge " + extraClasses, style: { background: actualColor } },
        letters,
    );
}

let dojoSentIframe: any = null;

/**
 * @deprecated Use {@link initiateDownload} instead. It has the exact same API, so you should be
 *     able to just swap it out for this one with no issue.
 */
export function dojoInitiateDownload(url: string, content?: any, method?: string) {
    // We have to clear the old iframe so that it can be opened again...
    if (dojoSentIframe) {
        dojoSentIframe.cancel();
        dojoSentIframe = null;
    }
    const parms: any = {};
    if (method && method.toUpperCase() === "POST") {
        content._csrf = dojo_cookie("XSRF-TOKEN");
        // If we are going to POST, we need a form.  IE won't work unless it's actually in the DOM tree.
        parms.form = Dom.create(
            "form",
            {
                style: "display:none;",
            },
            baseWindow.body(),
        );
        // Also, dojo does a really shitty job of converting various falsy types to values that
        // Spring can use, so we must sanitize the content.
        parms.content = {};
        if (content) {
            Object.entries<{ length: number }>(content).forEach(([key, val]) => {
                // null, undefined, and empty array should not be in the parameters.
                if (val != null && val.length !== 0) {
                    parms.content[key] = val;
                }
            });
        }
    } else {
        parms.content = content || {};
    }
    parms.url = url;
    // Default to GET (preserving old behavior)
    parms.method = method || "GET";
    dojoSentIframe = dojo_io_iframe.send(parms);
    // Clear the form, if we made one.
    if (parms.form) {
        Dom.destroy(parms.form);
    }
}

/**
 * Given a request param key and value, return a list of 0 or more hidden input elements which, if
 * attached to a form, will cause that form to include the given param in its requests.
 */
function makeDownloadFormInputs(paramKey: string, paramValue: unknown) {
    // Nullish params should just be excluded entirely
    if (paramValue === null || !Is.defined(paramValue)) {
        return [];
    }

    // Array params need to be encoded with one input per array element
    if (paramValue instanceof Array) {
        return paramValue.map((element) =>
            Dom.input({
                type: "hidden",
                name: paramKey,
                value: element,
            }),
        );
    }

    return [
        Dom.input({
            type: "hidden",
            name: paramKey,
            value: paramValue,
        }),
    ];
}

let prevIFrame: HTMLIFrameElement | null = null;

/**
 * Instruct the browser to begin a download of a resource at a specified URL
 *
 * This function doesn't provide any way to report download status or progress. For more
 * information about how this function works and possibilities for future improvements, see this
 * Aha idea: https://everlaw.aha.io/ideas/ideas/SW-I-140
 * @param url The URL of the resource to download. This can contain query params (though it may
 *     make more sense to specify those using the "params" parameter instead).
 * @param params Optional query parameters to send with the download request. These will either end
 *     up as query parameters for GET requests or as a form-encoded body for POST requests.
 * @param method Method to use when making download requests. Can be either GET or POST, defaults
 *     to GET.
 */
export function initiateDownload(url: string, params?: unknown, method: "GET" | "POST" = "GET") {
    // Since we can't tell when the download has completed, we have no way to clean up the iframe
    // once we're done with it. Instead, we just keep track of the previous invocation's iframe and
    // destroy it on the next call. This ensures there's at most 1 iframe in the DOM at any time.
    // The downside of this is that there can only ever be 1 download in flight at a time...
    if (prevIFrame !== null) {
        Dom.destroy(prevIFrame);
        prevIFrame = null;
    }

    const formInputs: HTMLInputElement[] = [];

    // Forms strip query params from their action URI, so we need to convert any query params
    // provided with the URL into form inputs to ensure they're actually sent
    const urlSearchParams = new URLSearchParams(url.split("?", 2)[1]);
    for (const [key, value] of urlSearchParams) {
        formInputs.push(...makeDownloadFormInputs(key, value));
    }

    for (const [key, value] of Object.entries(params ?? {})) {
        formInputs.push(...makeDownloadFormInputs(key, value));
    }

    if (method === "POST") {
        formInputs.push(...makeDownloadFormInputs("_csrf", dojo_cookie("XSRF-TOKEN")));
    }

    const form = Dom.form(
        {
            action: url,
            method: method,
        },
        ...formInputs,
    );

    const iframe = Dom.iframe({
        name: "initiateDownload-frame",
        style: "display: none;",
    });

    Dom.place(iframe, document.body);
    iframe.contentDocument?.body.append(form);

    form.submit();

    prevIFrame = iframe;
}

export interface Range<V> {
    begin?: V;
    end?: V;
}

export function createRange<T>(begin: T, end: T, obj?: any): Range<T> {
    const ret: Range<T> = obj || {};
    if (begin !== null) {
        ret.begin = begin;
    }
    if (end !== null) {
        ret.end = end;
    }
    return ret;
}

/**
 * Register a function to be called when Enter is pressed and released while in
 * the context of the given node.
 * @param node  the node to monitor for an Enter press
 * @param f  the function to call when Enter is pressed
 * @param [thisContext] the object that is represented by 'this' in the body of
 *   f
 * @return  an object with a remove function, which should be called to
 *   unregister the function.
 */
export function onSubmit(node: HTMLElement, f: () => void, thisContext?: any) {
    return dojo_on(node, "keypress", (evt: Event) => {
        if (evt instanceof KeyboardEvent && evt.keyCode === dojo_keys.ENTER) {
            eventUtil.stop(evt);
            f.apply(thisContext);
        }
    });
}

/**
 * Returns a flexbox container with padding divs around the specified content, ensuring proper
 * alignment. At least one of 'horizontal' or 'vertical' must be specified. Integers from 1-4
 * inclusive for the ratio parameters can create up to a 4:1 ratio of padding on either side.
 */
export function alignedContainer(params: {
    /** The content to align */
    content: HTMLElement;
    /** Optional classes to apply to the container */
    cssClass?: string;
    /** Whether to wrap the content in a horizontal flexbox */
    horizontal?: boolean;
    /** Whether to wrap the content in a vertical flexbox */
    vertical?: boolean;
    /** The padding ratio above the content (only when vertical = true, default = 1) */
    aboveRatio?: number;
    /** The padding ratio below the content (only when vertical = true, default = 1) */
    belowRatio?: number;
    /** The padding ratio to the left of the content (only when horizontal = true, default = 1) */
    leftRatio?: number;
    /** The padding ratio to the right of the content (only when horizontal = true, default = 1) */
    rightRatio?: number;
}) {
    Dom.addClass(params.content, "aligned-content");
    let container: HTMLElement = params.content;
    let finalContainer: HTMLElement | null = null;
    if (params.horizontal) {
        // Wrap the content in a horizontal flexbox.
        container = Dom.div({ class: "aligned-container" }, [
            Dom.div({ class: "aligned-padding-" + (params.leftRatio ? params.leftRatio : "1") }),
            params.content,
            Dom.div({ class: "aligned-padding-" + (params.rightRatio ? params.rightRatio : "1") }),
        ]);
        finalContainer = container;
    }
    if (params.vertical) {
        // Wrap the container in a vertical flexbox.
        finalContainer = Dom.div({ class: "aligned-container vertical" }, [
            Dom.div({ class: "aligned-padding-" + (params.aboveRatio ? params.aboveRatio : "1") }),
            container,
            Dom.div({ class: "aligned-padding-" + (params.belowRatio ? params.belowRatio : "1") }),
        ]);
    }
    if (!finalContainer) {
        throw new Error("alignedContainer: must specify at least one of horizontal or vertical");
    }
    params.cssClass && Dom.addClass(finalContainer, params.cssClass);
    return finalContainer;
}

export function noSharedObjectMsg(objTypes: string) {
    return alignedContainer({
        content: Dom.div({ class: "no-shared-msg-container" }, [
            Dom.div({ class: "no-shared-msg-graphic", "aria-hidden": "true" }),
            Dom.div(
                { class: "no-shared-msg-text" },
                "You have not been shared any " + objTypes + " yet.",
            ),
        ]),
        horizontal: true,
        vertical: true,
    });
}

export function highlightify(
    el: HTMLElement,
    originalString: string,
    filter: string,
    clazz = "highlighted",
) {
    const content: Dom.Content[] = [];
    if (filter) {
        const re = new RegExp(Str.regexEscape(filter), "ig");
        let currIdx = 0;
        originalString.split(re).forEach((val, ind, arr) => {
            content.push(val);
            if (ind !== arr.length - 1) {
                const start = currIdx + val.length;
                const origMatched = originalString.substring(start, start + filter.length);
                content.push(Dom.span({ class: clazz }, origMatched));
            }
            currIdx += val.length + filter.length;
        });
    } else {
        content.push(originalString);
    }
    Dom.setContent(el, content);
}

function getScrollbarWidth() {
    const elem = Dom.create(
        "div",
        {
            style: {
                position: "absolute",
                visibility: "hidden",
                overflowY: "scroll",
            },
        },
        document.body,
    );
    const res = elem.offsetWidth;
    Dom.destroy(elem);
    return res;
}

export const SCROLLBAR_WIDTH = getScrollbarWidth();

/** Optional parameters for the callout() function below. */
export interface CalloutParams {
    // The color of the callout. Defaults to Color.green10.
    color?: string;
    // The background color of the node(s) being animated, used as the "to" value in the animation.
    // If not specified, a transparent value will be used, which will leave the node without a
    // background color (just fine if the node isn't styled with a color).
    bgColor?: string;
    // Duration of the callout in milliseconds. Default is 1000.
    msDuration?: number;
    // Optional vars passed directly to TweenLite, for example you can specify an onStart or
    // onComplete callback here.
    extraVars?: any;
}

/**
 * Call out one or more nodes by flashing their background color. See params for details. Returns
 * the list of created animations.
 *
 * Note that after calling this function, the element will have a background-color of transparent
 * (or the specified color), which will override future css background color changes. You can avoid
 * this by removing the background color in an onComplete callback.
 */
export function callout(nodes: HTMLElement | HTMLElement[], params: CalloutParams = {}) {
    const anims: gsap.core.Animation[] = [];
    const fromVars = { backgroundColor: params.color || ColorTokens.BACKGROUND_CALLOUT };
    const toVars: { backgroundColor?: string } = params.extraVars || {};
    // Default to transparent.
    toVars.backgroundColor = params.bgColor || "rgba(255, 255, 255, 0)";
    Arr.wrap(nodes).forEach((n) => {
        // Force any existing animations on this node (or its children) to complete.
        Util.finishTweens(n);
        anims.push(
            gsap.fromTo(n, fromVars, { duration: (params.msDuration || 1000) / 1000, ...toVars }),
        );
    });
    return anims;
}

// Every widget that has a TextBox should be setting either textBoxLabelContent or
// textBoxAriaLabel at construction time or later
export interface WidgetWithTextBoxParams {
    textBoxAriaLabel?: string;
    textBoxLabelContent?: Dom.Content;
    textBoxLabelPosition?: TextBox.LabelPosition;
}

export interface WidgetWithTextBox {
    setTextBoxAriaLabel: (ariaLabel: string) => void;
    setTextBoxLabelContent?: (content: Dom.Content) => void;
    setTextBoxLabelPosition?: (position: TextBox.LabelPosition) => void;
}
